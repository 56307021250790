import Link from 'next/link';
import { memo } from 'react';
import Col from 'react-bootstrap/Col';

import styles from '@/styles/Components/FrontContainer/Footer.module.scss';

const FooterCommanLinks = () => {
  return (
    <>
      <Col md="2">
        <div className="footer-widget">
          <div className={styles.footerTitle}>
            <h5>About Us</h5>
          </div>
          <div className={styles.footerContent}>
            <ul>
              <li>
                <Link href="/ads" className="clickable">
                  Manage your ad
                </Link>
              </li>
              <li>
                <Link href="/terms-and-condition" className="clickable">
                  Terms and Conditions
                </Link>
              </li>

              <li>
                <Link href="/contact-us" className="clickable">
                  Contacts Us
                </Link>
              </li>
              <li>
                <Link href="/privacy-policy" className="clickable">
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </Col>
    </>
  );
};

export default memo(FooterCommanLinks);
